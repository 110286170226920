<template>
  <div>
    <!-- <user-list-add-new :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive" :role-options="roleOptions"
        :plan-options="planOptions" @refetch-data="refetchData" />
  
      <users-list-filters :role-filter.sync="roleFilter" :plan-filter.sync="planFilter" :status-filter.sync="statusFilter"
        :role-options="roleOptions" :plan-options="planOptions" :status-options="statusOptions" /> -->

    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row class="d-flex justify-content-between w-100">
          <!-- Per Page -->
          <b-col md="6" class="d-flex mt-1">
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :clearable="false"
              v-model="per_page"
              class="per-page-selector d-inline-block mx-50"
              :options="['5', '10', '20', '50', '100']"
              @input="changePagination()"
            />
            <!-- Search -->
          </b-col>
          <!-- <b-col md="6" class="d-flex mt-1 ml-100">
            <div class="w-100 d-flex justify-content-end">
              <div>
                <b-form-input
                  class="d-inline-block mr-1"
                  placeholder="Search..."
                  v-model="search"
                  @input="handleSearch"
                />
              </div>
              <div class="ml-1">
                <v-select
                  style="width: 150px !important"
                  :clearable="false"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :searchable="false"
                  v-model="searchTerm"
                  placeholder="Select"
                  class="per-page-selector d-inline-block mx-50"
                  label="name"
                  tbody-tr-class="custom-table-row"
                  :options="searchOptions"
                  @input="changeSearch()"
                />
              </div>
            </div>
          </b-col> -->
        </b-row>
      </div>
      <b-table
        :busy="isLoading"
        ref="refUserListTable"
        class="position-relative"
        :items="tabledata"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
        @sort-changed="onSortChanged"
      >
        <template #cell(id)="data">
          <span class="text-primary font-weight-bold" style="font-size: 15px">
            {{ data.index + 1 }}
          </span>
        </template>
        <template #table-busy>
          <div class="text-center text-secondary my-2">
            <b-spinner class="align-middle"></b-spinner>
          </div>
        </template>
        <template #cell(f_name)="data">
          <div>
            <div>
              <span class="mr-1">
                <b-avatar
                  size="32"
                  :src="data.item.avatar"
                  :text="data.item.f_name.substring(0, 1)"
                />
              </span>
              {{ data.item.f_name }}
            </div>
          </div>
        </template>
        <template #cell(startdate)="data">
          <div class="d-flex w-100 align-items-center">
            <div
              v-if="
                data.item.customer_plans && data.item.customer_plans.length > 0
              "
            >
              <p class="d-flex">
                {{
                  moment(
                    data.item.customer_plans[0].startdate,
                    "YYYY-MM-DD"
                  ).format("DD/MM/YYYY")
                }}
              </p>
            </div>
          </div>
        </template>
        <template #cell(type)="data">
          <div class="d-flex w-100 align-items-center">
            <p class="titleName">{{ data.item.type }}</p>
          </div>
        </template>
        <template #cell(phone)="data">
          <div class="d-flex w-100 align-items-center">
            <p class="titleName">{{ data.item.phone }}</p>
          </div>
        </template>
        <template #cell(enddate)="data">
          <div class="d-flex align-items-center">
            <p class="d-flex">
              {{
                moment(
                  data.item.customer_plans[0].enddate,
                  "YYYY-MM-DD"
                ).format("DD/MM/YYYY")
              }}
            </p>
          </div>
        </template>
        <template #cell(Action)="tabledata">
          <feather-icon
            class="mr-2 cursor-pointer"
            icon="XIcon"
            size="25"
            @click="openModel('Reject', tabledata.item.id)"
          />
          <feather-icon
            class="cursor-pointer"
            icon="CheckIcon"
            size="25"
            @click="openModel('Approve', tabledata.item.id)"
          />
        </template>
      </b-table>
      <div>
        <b-modal
          v-model="RejectModal"
          no-close-on-backdrop
          hide-footer
          centered
          size="md"
        >
          <validation-observer ref="simple">
            <b-form ref="myFormMain">
              <b-row class="p-1">
                <b-col md="12" class="my-2 text-center">
                  <!-- {{ CustomerData }} -->
                  <h3
                    class="font-weight-bolder"
                    style="font-size:26px; font-family:Public Sans, sans's serif"
                  >
                    Membership Plan
                  </h3>
                </b-col>
                <b-col cols="12">
                  <b-form-group>
                    <label class="font-weight-bold mt-1" for="h-first-name"
                      >Reason For Rejection</label
                    >
                    <validation-provider
                      #default="{ errors }"
                      rules="required"
                      name="Reason"
                    >
                      <b-form-input
                        type="text"
                        v-model="reject"
                        maxlength="100"
                        placeholder="Reason For Rejection"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- @click="Congo" -->
                <b-col md="12" class="d-flex justify-content-center mt-2">
                  <b-button
                    type="submit"
                    variant="primary"
                    class="mr-1"
                    @click.prevent="handleApproveForm()"
                    style="font-family: Public Sans"
                  >
                    Submit
                  </b-button>
                  <b-button
                    type="reset"
                    @click="CloseModal"
                    variant="primary"
                    style="font-family: Public Sans"
                  >
                    Cancel
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-modal>
      </div>
      <div>
        <b-modal
          v-model="selectPlan"
          no-close-on-backdrop
          hide-footer
          centered
          size="md"
        >
          <validation-observer ref="simpleRules">
            <b-form ref="myFormMain">
              <b-row class="p-1">
                <b-col md="12" class="my-2 text-center">
                  <h3
                    class="font-weight-bolder"
                    style="font-size: 26px; font-family: Public Sans"
                  >
                    Membership Details
                  </h3>
                </b-col>
                <b-col cols="12" v-if="suspend_type == 'Reject'">
                  <b-form-group>
                    <label class="font-weight-bold mt-1" for="h-first-name"
                      >Reason For Rejection</label
                    >
                    <validation-provider
                      #default="{ errors }"
                      rules="required"
                      name="Reason"
                    >
                      <b-form-input
                        type="text"
                        v-model="reject"
                        maxlength="100"
                        placeholder="Reason For Rejection"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="12" v-if="suspend_type == 'Approve'">
                  <label for="input">Select Plans</label>
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                    name="Select Plan"
                  >
                    <v-select
                      placeholder="Select Your Plans"
                      label="name"
                      :searchable="false"
                      :options="getOptions('plan')"
                      v-model="plans"
                    ></v-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
                <b-col md="12" v-if="suspend_type == 'Approve'">
                  <label>Start Date</label>
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                    name="Start date"
                  >   
                    <flat-pickr
                      v-model="startdate"
                      :config="{ dateFormat: 'd/m/Y' }"
                      class="form-control flat-picker"
                      style="background-color:transparent;"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
                <!-- @click="Congo" -->
                <b-col md="12" class="d-flex justify-content-center mt-2">
                  <b-button
                    type="submit"
                    variant="primary"
                    class="mr-1"
                    @click.prevent="handleApproveForm()"
                    style="font-family: Public Sans"
                  >
                    Submit
                  </b-button>
                  <b-button
                    type="reset"
                    @click="CloseModal"
                    variant="primary"
                    style="font-family: Public Sans"
                  >
                    Cancel
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-modal>
      </div>
      <div class="w-100 d-flex justify-content-between p-1">
        <!-- Pagination -->
        <div>
          <span class="text-nowrap">
            Showing {{ paginationStart }} to {{ paginationEnd }} of
            {{ paginationTotal }}</span
          >
        </div>
        <template>
          <b-pagination
            :total-rows="paginationTotal"
            :per-page="per_page"
            v-model="paginationValue"
            first-number
            last-number
            class="custom-pagination mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
            @input="changePagination()"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </template>
      </div>
    </b-card>
  </div>
</template>
  
<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BProgress,
  BFormGroup,
  BFormSelect,
  BForm,
  BInputGroup,
  BInputGroupAppend,
  BSpinner,
} from "bootstrap-vue";
import vSelect from "vue-select";
import axios from "../../../../component/axios";
// @/component/axios
import moment from "moment";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import _ from "lodash";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";


export default {
  components: {
    BFormSelect,
    BInputGroup,
    BCard,
    BRow,
    moment,
    required,
    BInputGroupAppend,
    BCol,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BFormGroup,
    required,
    BDropdown,
    BDropdownItem,
    BPagination,
    BProgress,
    BSpinner,
    vSelect,
    BForm,
    flatPickr,
  },
  data() {
    return {
      columnFilters: {},
      params: {},
      startdate:'',
      tableColumns: [
        {
          key: "id",
          label: "NO",
          sortable: true,
        },
        { key: "f_name", label: "Name", sortable: true },
        { key: "type", label: "User Type" },
        { key: "startdate", label: "Preview Plan Start Date" },
        { key: "enddate", label: "Preview Plan End Date" },
        // { key: 'total', sortable: true, formatter: val => `$${val}` },
        // { key: 'balance', sortable: true },
        { key: "phone", label: "Contact", sortable: true },
        { key: "Action", label: "Action" },
      ],
      searchTerm: { name: "Name", value: "f_name" },
      search: "",
      height: "",
      tabledata: [],
      accessToken: localStorage.getItem("accessToken"),
      baseApi: process.env.VUE_APP_APIENDPOINT,
      weight: "",
      isLoading: false,
      moment,
      data: [],
      isSortDirDesc: true,
      sortBy: "",
      orderBy: true ? "ASC" : "DESC",
      loader: true,
      AllTrainer: [],
      ifEdit: false,
      id: 0,
      paginationStart: 0,
      paginationEnd: 0,
      paginationValue: 1,
      paginationTotal: 0,
      per_page: 10,
      searchOptions: [
        { name: "Name", value: "name" },
        { name: "UserType", value: "usertype" },
      ],
      planData: [],
      selectedID: 0,
      selectPlan: false,
      plans: "",
      originalDate: moment().format("DD/MM/YYYY"),
      reject: "",
      customerID: "",
      RejectModal: false,
      suspend_type: "",
    };
  },
  watch: {},
  mounted() {
    this.getClientData();
  },
  methods: {
    onSortChanged(e) {
      this.sortBy = e.sortBy;
      this.orderBy = e.sortDesc ? "DESC" : "ASC";
      // console.log(this.sortBy)
      // console.log(this.orderBy)
      this.getClientData();
    },
    filterTable: _.debounce(function () {
      this.params = {};
      for (let key in this.columnFilters) {
        if (
          this.columnFilters[key] &&
          typeof this.columnFilters[key] == "object"
        ) {
          this.params[key] = this.columnFilters[key]
            .map((inner) => inner.id)
            .join(",");
        } else {
          this.params[key] = this.columnFilters[key];
        }
      }
      this.params.page = this.paginationValue;
      this.getClientData();
    }, 1000),

    changePagination() {
      this.getClientData();
    },
    changeSearch() {
      if (this.search) {
        this.getClientData();
      }
    },
    async getClientData() {
      this.isLoading = true;
      let params = {};
      params.page = this.paginationValue;
      params.per_page = this.per_page;
      if (this.search) {
        params.search = this.search.id ? this.search.id : this.search;
        params.type = this.searchTerm.value;
      }
      if (this.sortBy) {
        params.sortBy = this.sortBy;
        params.orderBy = this.orderBy;
      }
      await axios({
        method: "GET",
        url: `${this.baseApi}dashboard/renewal-list-client`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        params: params,
      })
        .then((json) => {
          this.tabledata = json?.data.data.data;
          this.customerID =
            json?.data && json?.data.data.length > 0
              ? json?.data.data[0].id
              : "";
          this.planData =
            json?.data && json?.data.data.length > 0
              ? json?.data.data[0].customer_plans
              : "";
          this.paginationTotal = json?.data.data.total;
          this.paginationStart = json?.data.data.current_page;
          this.paginationEnd = json?.data.data.per_page;
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error, "error");
        });
    },
    handleRejectForm() {
      this.$refs.simple.validate().then(async (success) => {
        if (success) {
          this.RejectClient("Reject", this.selectedID);
        }
      });
    },

    handleApproveForm() {
      this.$refs.simpleRules.validate().then(async (success) => {
        if (success === false) {
          this.$swal({
            title: "Warning",
            timer: 5000,
            text: "Please fill in all fields",
            icon: "warning",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        }
        if (success) {
          this.ApprovePlan();
        }
      });
    },
    ApprovePlan() {
      let customerData;
      if (this.suspend_type == "Reject") {
        customerData = {
          action: this.suspend_type,
          id: this.selectedID,
          rejectreason: this.reject,
        };
      } else if (this.suspend_type == "Approve") {
        let endDate;

        switch (this.plans.id) {
          case 1:
            endDate = moment(this.startdate, "DD/MM/YYYY")
              .add(1, "month")
              .format("YYYY-MM-DD");
            break;
          case 2:
            endDate = moment(this.startdate, "DD/MM/YYYY")
              .add(3, "months")
              .format("YYYY-MM-DD");
            break;
          case 3:
            endDate = moment(this.startdate, "DD/MM/YYYY")
              .add(6, "months")
              .format("YYYY-MM-DD");
            break;
          case 4:
            endDate = moment(this.startdate, "DD/MM/YYYY")
              .add(9, "months")
              .format("YYYY-MM-DD");
            break;
          case 5:
            endDate = moment(this.startdate, "DD/MM/YYYY")
              .add(12, "months")
              .format("YYYY-MM-DD");
            break;
          default:
            // Handle other cases or set a default behavior
            break;
        }
        customerData = {
          action: this.suspend_type,
          id: this.selectedID,
          startdate: moment(this.startdate, "DD/MM/YYYY").format("YYYY-MM-DD"),
          enddate: endDate,
          plan_id: this.plans.id,
        };
      }
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}dashboard/approve-reject-renewal-plan/${this.selectedID}`,
        data: JSON.stringify(customerData),
      };
      axios(requestOptions)
        .then((response) => {
          this.$swal({
            title: "Submitted",
            text: response.data.massage
              ? `${response.data.massage}`
              : "Client Updated Successfully",
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
          this.getClientData();
          this.selectPlan = false;
          this.plans='';
          this.startdate='';
          this.reject = ''
          this.$forceUpdate();
        })
        .catch((error) => {
          if (error.response.data.message) {
            this.$swal({
              title: "Error!",
              text: error.response.data.message,
              icon: "error",
              timer: 5000,
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
          } else {
            this.$swal({
              title: "Error!",
              text: `${error}`,
              icon: "error",
              timer: 5000,
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
          }
        });
    },
    handleSearch: _.debounce(function () {
      this.getClientData();
    }, 1000),

    openModel(e, id) {
      this.suspend_type = e;
      this.reason = "";
      this.plans = "";
      this.selectPlan = true;
      this.selectedID = id;
    },
    openRejectModal(id) {
      this.RejectModal = true;
      this.selectedID = id;
    },
    CloseModal() {
      this.selectPlan = false;
      this.RejectModal = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

custom-pagination .page-item {
  border-radius: 0 !important;
}

.custom-pagination .page-link {
  border-radius: 0 !important;
}

.titleName {
  font-size: 15px;
  font-weight: 600;
  font-family: "Public Sans", sans-serif;
}
</style>
  
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
  
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
    